<div class="dashboard-myevent-content d-flex"
    [ngClass]="{'mobile': mobile}">
    <div class="page-header mb-1">
        <div class="sub-title">
            Mes événements
        </div>
    </div>
    <div class="my-events-contents" *ngIf="isEventToShow()">
        <button *ngIf="events.length > 1"
                (click)="prevEvent()"
                class="caroussel-arrow back-arrow">
            <nb-icon class="caroussel-icon" icon="arrow-ios-back-outline"></nb-icon>
        </button>
        <div class="custom-caroussel">
            <div class="whole-event card-shadow" (click)='onOpenEvent()'>
                <div [style.background-image]="getCurrentEventThumbnail()" class="hidden-mobile event-cover pointer"
                [ngClass]="{'event-cover-safari': isSafari}">
                </div>

                <img alt="Thumbnail mobile" class="hidden-browser" [src]="getCurrentEventThumbnailMobile()">
                
                <div class="flex-column pointer whole-event-content">
                    <div class="d-flex justify-content-between" [ngClass]="{
                        'events-item-cfl': eventToShow.type==='cfl',
                        'events-item-societe': eventToShow.type==='society'
                        }">
                        <span class="event-item-type hidden-mobile">{{'event.type.' + eventToShow.type | translate}}</span>
                        <div class="mt-1 hidden-mobile">
                            <nb-icon class="mr-2" icon='people-outline'></nb-icon>
                            <span *ngIf="eventToShow.userLimit > 0">
                                {{ eventToShow.totalRegistration }} / {{ eventToShow.userLimit }}
                            </span>
                            <span *ngIf="eventToShow.userLimit === 0 || eventToShow.userLimit === null">
                                {{ eventToShow.totalRegistration }} / ∞
                            </span>
                        </div>
                    </div>
                    <div class="d-flex text-center justify-content-center">
                        <h4> {{ eventToShow.title }} </h4>
                    </div>
                    <div class="d-flex align-items-center flex-grow-1">
                        <div class="col-xl-8 no-padding mt-2">
                            <div class="d-flex">
                                <nb-icon class="mr-2 icon-infos hidden-mobile" icon='info-outline'></nb-icon>
                                <div *ngIf="eventToShow.organize" class="flex-column hidden-mobile justify-content-center">
                                    <span> Organisateur </span>
                                </div>
                                <div *ngIf="eventToShow.registered" class="flex-column hidden-mobile justify-content-center">
                                    <span><strong> {{ 'event.registration.' + eventToShow.registrationStatus | translate }}</strong></span>
                                </div>
                            </div>
                            <div class="d-flex">
                                <nb-icon class="mr-2 icon-infos mt-1" icon='pin-outline'></nb-icon>
                                <div class="flex-column">
                                    <span *ngIf="eventToShow.address.name !== 'Lieux inconnu'">{{ eventToShow.address.name }}</span>
                                    <span class="hidden-mobile" *ngIf="eventToShow.address.houseNumber || eventToShow.address.street">
                                        {{ eventToShow.address.houseNumber }} {{ eventToShow.address.street}}
                                    </span>
                                    <span *ngIf="eventToShow.address.zipcode || eventToShow.address.city || eventToShow.address.country">
                                        {{ eventToShow.address.zipcode }} {{ eventToShow.address.city }} {{ eventToShow.address.country }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="flex-column align-items-center"
                                    [ngClass]="{
                                    'events-item-cfl': eventToShow.type==='cfl',
                                    'events-item-societe': eventToShow.type==='society'
                                    }">
                                <h4 class="hidden-mobile">{{ eventToShow.startDate | date:'dd' }}</h4>
                                <h5 class="hidden-mobile">{{ eventToShow.startDate | date:'MMM' | uppercase }}</h5>
                                <h4 class="hidden-mobile">{{ eventToShow.startDate | date:'HH' }}h{{ eventToShow.startDate | date:'mm' }}</h4>

                                <h4 class="hidden-browser">{{ eventToShow.startDate | date:'dd' }} {{ eventToShow.startDate | date:'MMM' | uppercase }}</h4>
                                <h5 class="hidden-browser">{{ eventToShow.startDate | date:'HH' }}h{{ eventToShow.startDate | date:'mm' }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="eventToShow.registered">
                        <span class="hidden-browser"><br> {{ 'event.registration.' + eventToShow.registrationStatus | translate }}</span>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="eventToShow.organize">
                        <span class="hidden-browser"><br> {{ 'event.status.' + eventToShow.status | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <button *ngIf="events.length > 1"
                (click)="nextEvent()"
                class="caroussel-arrow front-arrow">
            <nb-icon class="caroussel-icon" icon="arrow-ios-forward-outline"></nb-icon>
        </button>
    </div>
    <div class="d-flex justify-content-center mt-4" *ngIf="events.length>1">
        <div *ngFor="let event of events; let i = index">
            <div [ngStyle]="{'background': i===currentEvent ? 'black' : 'white'}" class="change-event"></div>
        </div>
    </div>

    <div class="my-events-contents" *ngIf="!isEventToShow()">
        <div class="row">
            <div class="col-12 flex-aligned-column">
                <img src="assets/image-no-inscription.png" alt="Image pas de participations"/>
                <div class="mt-4 flex-aligned-column">
                    <span style="font-size: 1.1rem;">Tu n'es inscrit à aucune activité.</span>
                    <button status="primary"
                            class="mt-2"
                            size="medium"
                            ghost
                            [routerLink]="'/events'"
                            nbButton>
                        Consulter la liste</button>
                </div>
            </div>
        </div>
    </div>
</div>
