<nb-card class="user-form-dialog-card">

    <nb-card-body>
        <h3 *ngIf="ticketsToPay.length === 1">Rappel de paiement d'un ticket</h3>
        <h3 *ngIf="ticketsToPay.length > 1">Rappel de paiement des tickets</h3>
        <p *ngIf="ticketsToPay.length === 1">Si tu as déjà payé le ticket suivant, tu peux cocher la case ci-dessous.</p>
        <p *ngIf="ticketsToPay.length > 1">Si tu as déjà payé les tickets suivants, tu peux cocher les cases ci-dessous.</p>

        <div *ngFor="let ticketToPay of ticketsToPay; let i = index">
            <div class="container-fluid">
                <!-- names -->
                <div class="row mb-3">
                    <div class="col-5 col-xl-3">
                        <span class="bold">Ticket #{{ i+1 }}</span>
                    </div>
                    <div class="col-7 col-xl-9">
                        {{ ticketToPay.ticketName }} à {{ ticketToPay.partnershipName }}
                    </div>
                </div>
                <!-- date -->
                <div class="row mb-3">
                    <div class="col-5 col-xl-3">
                        <span class="bold">Commandé le</span>
                    </div>
                    <div class="col-7 col-xl-9">
                        {{ ticketToPay.createdAt | date:'medium' }}
                    </div>
                </div>
                <!-- quantity -->
                <div class="row mb-3">
                    <div class="col-5 col-xl-3">
                        <span class="bold">Quantité</span>
                    </div>
                    <div class="col-7 col-xl-9">
                        {{ ticketToPay.quantity }}
                    </div>
                </div>
                <!-- payment -->
                <div class="row mb-3">
                    <div class="col-5 col-xl-3">
                        <span class="bold">Paiement</span>
                    </div>
                    <div class="col-7 col-xl-9">
                        {{ ticketToPay.price }} € par {{ 'ticketing.sale.' + ticketToPay.paymentType | translate}}
                    </div>
                </div>
                <!-- payment change -->
                <div class="row mb-3 pt-4">
                    <div class="col-8 col-xl-3 d-flex align-items-center">
                        <span class="bold">J'ai payé ce billet</span>
                    </div>
                    <div class="col-4 col-xl-9">
                        <nb-toggle status="info"
                            [(checked)]="userResponse[i].hasPayed">
                        </nb-toggle>
                    </div>
                </div>
                <div class="line"></div>
            </div>
        </div>
        
    </nb-card-body>
    <nb-card-footer class="dialog-footer">
        <div class="btn-dialog-group">
            <button nbButton
                    status="primary"
                    size="medium"
                    ghost
                    class="mr-1"
                    (click)="onClose()">
                Annuler
            </button>
            <button nbButton
                    size="medium"
                    shape="round"
                    class="btn-admin add-event"
                    (click)="onValidate()">
                Confirmer
            </button>
        </div>
    </nb-card-footer>
</nb-card>
