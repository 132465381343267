import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Event } from '../../shared/interfaces/event';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { EventService } from '../../core/services/event/event.service';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { DocumentService } from '../../core/services/document/document.service';
import { ToastersService } from '../../core/services/toasters/toasters.service';
import { RegistrationService } from 'src/app/core/services/registration/registration.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-myevents',
  templateUrl: './dashboard-myevents.component.html',
  styleUrls: ['./dashboard-myevents.component.scss'],
})
/*
    Gestion des événements liés à l'utilisateur connecté dans l'encadré se trouvant sur le dashboard
*/
export class DashboardMyeventsComponent implements OnInit {
  isSafari: boolean;
  private thumbnailImage: SafeStyle[];
  private thumbnailImageMobile: SafeUrl[];
  events: Event[] = [];
  @Input()
  mobile: boolean;

  @Output()
  loadingFinished: EventEmitter<void>;

  // variables for carousel
  eventToShow: Event;
  eventsLength: number;
  currentEvent = 0;

  // loading variable, 1 per subscribe
  loadingThumbnail = false;
  loadingEvents = false;

  defaultTouch = { x: 0, y: 0, time: 0 };

  constructor(
    private readonly _eventService: EventService,
    private readonly _datePipe: DatePipe,
    private readonly _documentService: DocumentService,
    private readonly _toastersService: ToastersService,
    private readonly _sanitizer: DomSanitizer,
    private readonly _router: Router,
    private readonly _registrationService: RegistrationService
  ) {
    this.loadingFinished = new EventEmitter<void>();
  }

  ngOnInit(): void {
    // safari cannot display event image
    // need to know if current browser is safari in order to fix it
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent?.includes('safari') && !agent?.includes('chrome')) {
      this.isSafari = true;
    }
    this.fetchEvents();
  }

  /* PRIVATE METHODS */
  /* INIT METHODS */

  initMyEvents(): void {
    if (this.events.length > 0) {
      this.eventToShow = this.events[this.currentEvent];
      this._registrationService.fetchTotalRegistration(this.eventToShow.idActEvent).subscribe(
        (response) => (this.eventToShow.totalRegistration = response.totalRegistered + response.extrasRegistered),
        (error) => this._toastersService.showErrorToast(error)
      );
    }
  }

  initThumbnails(): void {
    this.loadingThumbnail = true;
    this.thumbnailImage = new Array<any>(this.events.length);
    this.thumbnailImageMobile = new Array<any>(this.events.length);

    if (this.eventsLength === 0) {
      this.loadingThumbnail = false;
      this.isComponentLoaded();
    }
    this.events.forEach((event: Event, index: number) => {
      this._documentService
        .findThumbnailEvent(event.idActEvent)
        .pipe(
          finalize(() => {
            this.loadingThumbnail = false;
            this.isComponentLoaded();
          })
        )
        .subscribe(
          (file: Blob) => {
            const urlImage = URL.createObjectURL(file);
            const styleImage = `url(${urlImage})`;
            this.thumbnailImage[index] = this._sanitizer.bypassSecurityTrustStyle(styleImage);
            this.thumbnailImageMobile[index] = this._sanitizer.bypassSecurityTrustUrl(urlImage);
          },
          () => {
            this._toastersService.showWarningToaster(`Impossible de trouver l'image pour l'événement ${event.title}.
                      L'image par défaut a été utilisée`);
            const styleImage = `url(../../assets/no-img.png)`;
            this.thumbnailImage[index] = this._sanitizer.bypassSecurityTrustStyle(styleImage);
            this.thumbnailImageMobile[index] = this._sanitizer.bypassSecurityTrustUrl(styleImage);
          }
        );
    });
  }

  /* ON USER ACTION METHODS */
  onOpenEvent(): void {
    this._router.navigate([`events/info/${this.eventToShow.idActEvent}`]);
  }

  /* OTHER METHODS */
  fetchEvents(): void {
    this.loadingEvents = true;
    this._eventService
      .fetchMyEvents({
        minDate: this._datePipe.transform(new Date(), 'dd-MM-yyyy'),
        cfl: true,
        societe: true,
        showPast: false,
        holiday: false,
        deleted: false,
        future: false,
      })
      .subscribe(
        (events) => {
          this.events = events.sort(
            (objA, objB) => new Date(objA.startDate).getTime() - new Date(objB.startDate).getTime()
          );
          this.eventsLength = events.length;
          this.initThumbnails();
          this.initMyEvents();
        },
        (error: HttpErrorResponse) => {
          this._toastersService.showErrorToast(error);
          this.loadingEvents = false;
          this.isComponentLoaded();
        },
        () => {
          this.loadingEvents = false;
          this.isComponentLoaded();
        }
      );
  }

  isEventToShow(): boolean {
    return this.events.length > 0;
  }

  isComponentLoaded(): void {
    if (!this.loadingThumbnail && !this.loadingEvents) {
      this.loadingFinished.emit();
    }
  }

  nextEvent(): void {
    if (this.currentEvent + 1 < this.events.length) {
      this.currentEvent += 1;
    } else {
      this.currentEvent = 0;
    }
    this.eventToShow = this.events[this.currentEvent];
    this._registrationService.fetchTotalRegistration(this.eventToShow.idActEvent).subscribe(
      (response) => (this.eventToShow.totalRegistration = response.totalRegistered + response.extrasRegistered),
      (error) => this._toastersService.showErrorToast(error)
    );
  }

  prevEvent(): void {
    if (this.currentEvent - 1 >= 0) {
      this.currentEvent -= 1;
    } else {
      this.currentEvent = this.events.length - 1;
    }
    this.eventToShow = this.events[this.currentEvent];
    this._registrationService.fetchTotalRegistration(this.eventToShow.idActEvent).subscribe(
      (response) => (this.eventToShow.totalRegistration = response.totalRegistered + response.extrasRegistered),
      (error) => this._toastersService.showErrorToast(error)
    );
  }

  getCurrentEventThumbnail(): SafeStyle {
    return this.thumbnailImage[this.currentEvent];
  }

  getCurrentEventThumbnailMobile(): SafeUrl {
    return this.thumbnailImageMobile[this.currentEvent];
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];

    // check the events
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.y = touch.pageY;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaTime = event.timeStamp - this.defaultTouch.time;

      // simulte a swipe -> less than 500 ms and more than 60 px
      if (deltaTime < 500) {
        // touch movement lasted less than 500 ms
        if (Math.abs(deltaX) > 60) {
          // delta x is at least 60 pixels
          if (deltaX > 0) {
            this.doSwipeRight();
          } else {
            this.doSwipeLeft();
          }
        }
      }
    }
  }

  doSwipeLeft() {
    this.nextEvent();
  }

  doSwipeRight() {
    this.prevEvent();
  }
}
