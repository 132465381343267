<nb-card class="event-info-dialog card-shadow">
    <nb-card-header class="dialog-header">
        <h3>Changelog</h3>
    </nb-card-header>
    <nb-card-body>
        <div class="container-fluid" style="max-height: 45vh;overflow-y: auto;">
            <p style="font-size: 1.1rem; line-height: 1.7rem;">
                <strong>Version 1.6.1 - janvier 2025</strong><br>
                - Correctifs, stabilisation suivant la MEP 1.6.0<br>
                <br>
                <strong>Version 1.6.0 - décembre 2024</strong><br>
                - Ajout d'une notification visuelle pour les GO lors d'inscription en attente<br>
                - Ajout du tri des événements par catégorie<br>
                - Highlight lorsqu'un tri des évenements est actif<br>
                - Mise à jour des règles d'affichage des participants<br>
                - Mise à jour des conditions de retrait sur la page croissant<br>
                - Mise à jour visuelle des modales<br>
                - Nouveaux effets pour la barre de navigation<br>
                - Refonte et optimisation du chargement des images de la billetterie<br>
                <br>> Administration<br>
                - Ajout du nombre total de billet dans le filtre des ventes<br>
                - Ajout d'une nouvelle limite pour le nombre d'accompagnants autorisé<br>
                - Désactivation de l'inscription automatique si l'événement est complet<br>
                - Nouvelle page de suivi des relances de paiement<br>
                - Paramètrer la taille de la liste sur la gestion des droits<br>
                - Refonte de la gestion en base des organisateurs<br>
                <br>
                <strong>Version 1.5.4 - août 2024</strong><br>
                - Pérénisation du partenariat Philibert<br>
                - Option pour mettre en avant certains billets<br>
                - Nouveaux logos évenementiels<br>
                - Correctif sur l'affichage des événements<br>
                - Correctif sur la liste des organisateurs<br>
                - Améliorations visuelles diverses<br>
                <br>
                <strong>Version 1.5.3 - août 2024</strong><br>
                - Ajout d'un système de log pour la gestion des croissants<br>
                - Modification du tarif au moment d'une vente<br>
                - Optimisation du chargement des événements<br>
                - Fix sur l'import des vacances scolaires<br>
                - Fix sur l'API de localisation des événements<br>
                <br>
                <strong>Version 1.5.2 - juillet 2024</strong><br>
                - Ajout du partenariat avec Philibert<br>
                - Modification de la structure de la page Billetterie<br>
                <br>
                <strong>Version 1.5.1 - juillet 2024</strong><br>
                - Optimisation du chargement des pages<br>
                - Ajout d'un filtre événements futurs pour les administrateurs<br>
                - Correction d'un bug empêchant l'utilisateur de s'inscrire aux croissants<br>
                - Ajout du total au moment d'une vente<br>
                - Améliorations diverses<br>
                <br>
                <strong>Version 1.5.0 - juin 2024</strong><br>
                - Réactivation de l'édition et la suppression des commentaires<br>
                - Suppression des modules factures et budget<br>
                - Fix le décalage des colones dans l'export excel d'un formulaire<br> 
                - Ajout du binome croissant de la semaine dans la vue mobile<br> 
                - Désactiver la suppression d'utilisateurs administrateurs<br>
                - Parser correctement les noms à partir des mails<br>
                - Migration SpringBoot 3<br>
                <br>
                <strong>Version 1.4.0 - mai 2024</strong><br>
                - Ajout de la section petit-dejeuner pour la gestion des croissants à l'agence<br>
                - Nouveau rappel envoyé par mail le jour d'un événement à tous les participants validés<br>
                - Nouveau template pour tous les mails provenant de Act'InTech<br>
                - Fix pour l'affichage des descriptions d'événements<br>
                - Modification des droits sur la billetterie<br>
                <br>
                <strong>Version 1.3.3 - mars 2024</strong><br>
                - Suppression manuelle des utilisateurs inactifs<br>
                - Fix recherche d'événement<br>
                - Fix pour l'affichage des images en mobile<br>
                <br>
                <strong>Version 1.3.2 - février 2024</strong><br>
                - Retrait de la fonction indisponibilité des stocks<br>
                - #9 : Refresh de la page de vente d'un billet<br>
                - #10 : Modifier une vente via une pop-up<br>
                <br>
                <strong>Version 1.3.1 - décembre 2023</strong><br>
                - Réactivation des fonctions tickets à payer par les collaborateurs<br>
                - Amélioration de la gestion des commandes de billetterie<br>
                - Amélioration de l'enregistrement des ventes de billets<br>
                - Correctifs divers<br>
                <br>
                <strong>Version 1.3.0 - novembre 2023</strong><br>
                - TS-509 : Gestion du trigramme null d'un nouvel utilisateur<br>
                - TS-514 : Intégrer l'application croissant dans Act'InTech
                - TS-518 : Suppression des anciens rôles utilisateurs non utilisés<br>
                - TS-535 : Modification complète de la connexion Azure<br>
                - Optimisation du chargement des images de la billetterie<br>
                - L'âge est désormais facultatif dans les formulaires des accompagnants<br>
                - Changement de la taille de police des toaster<br>
                - Suppressions des fichiers inutilisés dans le back-end<br>
                <br>
                <strong>Version 1.2.0 - septembre 2023</strong><br>
                - TS-521 : La page billetterie pour les collaborateurs est de nouveau accessible avec une refonte graphique complète pour intégrer un peu de fraicheur ! 🍹<br>
                - TS-528 : Nettoyage et optimisations SQL / Back-end<br>
                - TS-530 : Nouveau visuel pour le menu de navigation desktop (inspiré par le system design)<br>
                - TS-532 : Afficher la photo de profil de l'utilisateur<br>
                - Ajout du pays où se déroule chaque activité proposée dans la billetterie<br>
                - Modifications graphiques sur l'écran d'accueil<br>
                <br>
                Administration :<br>
                - TS-522 : Refonte graphique de la liste des partenaires<br>
                - TS-523 : Refonte graphique de la mise à jour d'un partenaire<br>
                - TS-524 : Refonte graphique de la mise à jour d'une caisse<br>
                - TS-525 : Refonte graphique de la page des commandes<br>
                - TS-529 : Refonte graphique de la page des ventes <br>
                <br>
                <strong>Version 1.1.0 - août 2023</strong><br>
                - Ré-implémentation de la connexion Azure avec des autorisations moins permissives<br>
                - Mise à jour plus régulière des vacances scolaires<br>
                - Modification sur le slider d’événement (informations et taille)<br>
                - Le tri de la liste des événements se fait désormais par ordre chronologique<br>
                - Affichage simplifié des événements sur mobile<br>
                - TS-446 : Ajouter un versionning et un changelog<br>
                - TS-507 : Accès impossible depuis une URL statique<br>
                - TS-508 : Problème de refresh token, la session expire sans demander une nouvelle connexion<br>
                - TS-510 : Proposer une page de connexion<br>
                - TS-513 : Accès à la modération d’événements pour les administrateurs société
                <br><br>
                <strong>Version 1.0.0 - 04 avril 2023</strong><br>
                - Remise en route de la plateforme en version dégradée (sans la billetterie et avec une gestion des rôles simplifiées)<br>
                - Implémentation de la connexion Azure<br>
                - TS-476 : Limiter le nombre d'organisateur à 3<br>
                - TS-475 : La liste des inscrits n’affiche plus la date d'inscription<br>
                </p>
        </div>
    </nb-card-body>
</nb-card>
