import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-confirmation-dialog-without-input',
  templateUrl: './confirmation-dialog-without-input.component.html',
  styleUrls: ['./confirmation-dialog-without-input.component.scss']
})
export class ConfirmationDialogWithoutInputComponent implements OnInit {

  @Input()
  title: string;
  @Input()
  subtitle: string;
  @Input()
  content: string;
  @Input()
  doubleInputModal = true;
  closeBtnTxt: string;

  constructor(private readonly _dialogRef: NbDialogRef<any>) {
    this.title = 'Confirmation';
    this.subtitle = '';
    this.content = '';
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.closeBtnTxt = this.doubleInputModal ? 'Annuler' : 'Fermer';
  }

  /* PRIVATE METHODS */
  /* INIT METHODS */
  /* ON USER ACTION METHODS */

  onClose() {
    this._dialogRef.close({
      submit: false
    });
  }

  onSubmit() {
    this._dialogRef.close({
      submit: true
    });
  }

  /* OTHER METHODS */
}
